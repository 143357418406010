import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';

const ContactPage = () => {
  return (
    <Layout>
      <h3>Reach me out</h3>
      <p>You can contact me via:</p>
      <ul>
        <ContactMethod>
          Email:{' '}
          <ContactLink href='mailto:david@longblackcode.com'>
            david@longblackcode.com
          </ContactLink>
        </ContactMethod>
        <ContactMethod>
          LinkedIn:{' '}
          <ContactLink
            href='https://www.linkedin.com/in/david-kur'
            target='_blank'
          >
            david-kur
          </ContactLink>
        </ContactMethod>
        {/* <ContactMethod>
          Github:{' '}
          <ContactLink href="https://github.com/david-kur" target="_blank">
            david-kur
          </ContactLink>
        </ContactMethod> */}
      </ul>
    </Layout>
  );
};

const ContactMethod = styled.li`
  font-weight: 600;
  font-size: 1rem;
`;

const ContactLink = styled.a`
  color: ${(props) => props.theme.primaryColor};
`;

export default ContactPage;
